import React, {useState} from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import styled from "styled-components";
import {Input, Label, Select} from "../../components/Form";
import {ClientContactInfo} from "../../components/ClientContactInfo";
import moment from "moment";
import {StyledTextarea} from "../../components/styled/inputs";
import {ToDoInfo} from "../../components/ToDoInfo";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getDataFromGus} from "../../helpers/gus";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {faArrowRight, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {File} from "../../components/File";
import {Comment} from "../Comment/Comment";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`
const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 400px 320px;
    grid-gap: 60px;
    margin-right: 60px;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 160px 240px;
    align-items: center;
    margin-bottom: 10px;
`;

const InputSmallWrapper = styled(InputWrapper)`
    grid-template-columns: 150px 170px;
`;

const ClientInfoWrapper = styled.div`
    margin-left: 20px;
    margin-bottom: 10px;

    label {
        font-weight: bold;
        font-size: 11px;
    }

    textarea {
        margin-top: 2px;
    }

    a {
        cursor: pointer;
    }
`;

const NIPWrapper = styled(InputWrapper)`
    grid-template-columns: 160px 210px 30px;

    button {
        padding: 4px 4px;
    }
`;

const AddressWrapper = styled.div`
    margin: 10px;
`;

const StyledDivError = styled.div`
    color: red;
`;

export const VehicleForm = ({
                                initialValues,
                                onSubmit,
                                onSold,
                                vehicleId,
                                withClient,
                                client,
                                toDo,
                                disabled,
                                isLeader
                            }) => {
    const token = useSelector((store) => store.token);
    const isPartner = ['Partner'].includes(useSelector((store) => store.roleName));

    const [address, setAddress] = useState([]);
    const [invoiceScanFile, setInvoiceScanFile] = useState(null);
    const [soldModal, setSoldModal] = useState(null);
    const [leasingScanFile, setLeasingScanFile] = useState(null);
    const [protocolFile, setProtocolFile] = useState(null);
    const [newRegistrationNumberScanFile, setNewRegistrationNumberScanFile] = useState(null);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [globalPhotos, setGlobalPhotos] = useState([]);

    const setNet = (value, values, form) => {
        const grossValue = parseFloat(value);

        const eurValue = parseFloat(values.rate) > 0 ? grossValue / parseFloat(values.rate) : 0;

        const netValue = grossValue / 1.23;

        form.mutators.setValue('net_eur', eurValue.toFixed(2));

        if (values.salesDocument === 'FV VAT23%') {
            form.mutators.setValue('net', netValue.toFixed(2))
            form.mutators.setValue('net_eur', (eurValue / 1.23).toFixed(2));
        } else {
            form.mutators.setValue('net', grossValue.toFixed(2));
            form.mutators.setValue('net_eur', eurValue.toFixed(2));
        }
    }

    const setGross = (value, values, form) => {
        const netValue = parseFloat(value);

        const eurValue = parseFloat(values.rate) > 0 ? netValue / parseFloat(values.rate) : 0;

        const grossValue = netValue + netValue * 0.23;

        form.mutators.setValue('net_eur', eurValue.toFixed(2));

        if (values.salesDocument === 'FV VAT23%') {
            form.mutators.setValue('gross', grossValue.toFixed(2));
            form.mutators.setValue('gross_eur', (eurValue * 1.23).toFixed(2));
        } else {
            form.mutators.setValue('gross', netValue.toFixed(2));
            form.mutators.setValue('gross_eur', eurValue.toFixed(2));
        }
    }

    const calculateEur = (vehicle) => {
        const todoVehicle = vehicle;

        if (vehicle.rate > 0) {
            todoVehicle.net_eur = (todoVehicle.net / vehicle.rate).toFixed(2);
            todoVehicle.gross_eur = (todoVehicle.gross / vehicle.rate).toFixed(2);
        }

        if (vehicle.financeRate > 0) {
            todoVehicle.secondNet_eur = (todoVehicle.secondNet / vehicle.financeRate).toFixed(2);
            todoVehicle.payment_eur = (todoVehicle.payment / vehicle.financeRate).toFixed(2);
            todoVehicle.repurchase_eur = (todoVehicle.repurchase / vehicle.financeRate).toFixed(2);
            todoVehicle.netInstallment_eur = (todoVehicle.netInstallment / vehicle.financeRate).toFixed(2);
        }

        return todoVehicle;
    }

    const resetFiles = () => {
        setNewRegistrationNumberScanFile(null);
        setInvoiceScanFile(null);
        setLeasingScanFile(null);
        setProtocolFile(null);
    }

    const validate = (values) => {
        const errors = {}

        if (values.isStorage == null) {
            errors.isStorage = 'Pole wymagane';
        }

        if (!values.type) {
            errors.type = 'Wybierz rodzaj przedmiotu'
        }
        if (!values.condition) {
            errors.condition = 'Wybierz stan'
        }
        if (!values.mark) {
            errors.mark = 'Uzupełnij markę'
        }
        if (!values.model) {
            errors.model = 'Uzupełnij model'
        }
        return errors;
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalFiles) {
            return;
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const setPhotosToForm = (photos, index) => {
        const obj = {
            index,
            photos
        }
        if (!globalPhotos) {
            return;
        }
        if (globalPhotos.some(e => e.index === index)) {
            let newArr = [...globalPhotos].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalPhotos(newArr);
        } else {
            setGlobalPhotos(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Grid>
                <Form
                    mutators={{
                        ...arrayMutators,
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                    }}
                    validate={values => validate(values)}
                    initialValues={calculateEur(initialValues)}
                    onSubmit={values => {
                        onSubmit(values, newRegistrationNumberScanFile, invoiceScanFile, leasingScanFile, protocolFile, globalPhotos, globalFiles, resetFiles)
                    }}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <fieldset disabled={disabled} style={{display: "contents"}}>
                                    <Field
                                        name="isStorage"
                                        render={({input, meta}) => (
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Magazyn</Label>
                                                <Select {...input} id={input.name}>
                                                    <option value=''>Wybierz..</option>
                                                    <option value="1">Tak</option>
                                                    <option value="0">Nie</option>
                                                </Select>
                                                {meta.error && meta.touched &&
                                                    <StyledDivError>{meta.error}</StyledDivError>}
                                            </InputWrapper>
                                        )}/>
                                    <Field
                                        name='source'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Pochodzenie</Label>
                                                    <Select {...input} id={input.name} disabled={disabled}>
                                                        <option value="">Pochodzenie</option>
                                                        <option value="Nex1 Stock">Nex1 Stock</option>
                                                        <option value="Nex1 Produkcja">Nex1 Produkcja</option>
                                                        <option value="Obcy">Obcy</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name='type'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Rodzaj przedmiotu*</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Rodzaj przedmiotu</option>
                                                        <option value="Samochód osobowy">Samochód osobowy
                                                        </option>
                                                        <option value="Samochód ciężarowy do 3,5 t">Samochód
                                                            ciężarowy do
                                                            3,5 t
                                                        </option>
                                                        <option
                                                            value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                            ciężarowy
                                                            powyżej 3,5 t
                                                        </option>
                                                        <option value="Ciągnik samochodowy">Ciągnik
                                                            samochodowy
                                                        </option>
                                                        <option value="Naczepa">Naczepa</option>
                                                        <option value="Przyczepa ">Przyczepa</option>
                                                        <option value="Maszyna">Maszyna</option>
                                                        <option value="Motocykl">Motocykl</option>
                                                        <option value="Inne">Inne</option>
                                                    </Select>
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name="condition"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Stan*</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Stan</option>
                                                        <option value="Nowy">Nowy</option>
                                                        <option value="Nowy zarejestrowany">Nowy
                                                            zarejestrowany
                                                        </option>
                                                        <option value="Używany">Używany</option>
                                                    </Select>
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>

                                    <Field
                                        name="mark"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Marka*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="model"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Model*</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    {meta.error && meta.touched &&
                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="capacity"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Pojemność</Label>
                                                    <Input {...input} id={input.name} placeholder="" type="number"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="yearbook"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Rocznik</Label>
                                                    <Input {...input} id={input.name} placeholder="" type="number"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="firstRegistration"
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Data 1 rejestracji</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="registrationNumber"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Numer rejestracyjny</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="vin"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>VIN</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="equipmentVersion"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Wersja wyposażenia</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="mileage"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Przebieg</Label>
                                                    <Input {...input} id={input.name} placeholder="" type="number"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="salesDocument"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Dokument sprzedaży</Label>
                                                    <Select {...input} id={input.name}>
                                                        <option value="">Dokument sprzedaży</option>
                                                        <option value="FV VAT23%">FV VAT23%</option>
                                                        <option value="FV Marża">FV Marża</option>
                                                        <option value="Umowa sprzedaży">Umowa sprzedaży
                                                        </option>
                                                        <option value="Inny">Inny</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="listPrice"
                                        type="number"
                                        min="0"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cena katalogowa</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           step="0.01"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="rate"
                                        type="number"
                                        min="0"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Kurs EUR</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const rate = parseFloat(e.target.value);
                                                               form.mutators.setValue("net_eur",
                                                                   rate > 0 ? (parseFloat(values.net) / rate).toFixed(2) : null);
                                                               form.mutators.setValue("gross_eur",
                                                                   rate > 0 ? (parseFloat(values.gross) / rate).toFixed(2) : null);
                                                           }}
                                                           step="0.01"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="net"
                                        type="number"
                                        min="0"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cena netto</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           onChange={e => {
                                                               input.onChange(e)
                                                               setGross(e.target.value, values, form);
                                                           }}
                                                           step="0.01"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="net_eur"
                                        type="number"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cena netto EUR</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           disabled/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="gross"
                                        type="number"
                                        min="0"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cena brutto</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           onChange={e => {
                                                               input.onChange(e)
                                                               setNet(e.target.value, values, form);
                                                           }}
                                                           step="0.01"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="gross_eur"
                                        type="number"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Cena brutto EUR</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           disabled/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="discount"
                                        type="number"
                                        min="0"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Rabat%</Label>
                                                    <Input {...input} id={input.name} placeholder=""
                                                           step="0.01"/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="provider"
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Dostawca</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputWrapper>
                                            </>
                                        )}/>
                                    <Field
                                        name="nip"
                                        render={({input, meta}) => (
                                            <>
                                                <NIPWrapper>
                                                    <Label htmlFor={input.name}>NIP dostawcy</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                    {!disabled && <Button type="button" inactive small onClick={() => {
                                                        if (values.nip) {
                                                            getDataFromGus(values.nip, token, (data) => {
                                                                form.mutators.setValue('provider', data.name)
                                                                setAddress(`${data.street} ${data.propertyNumber}, ${data.city}, ${data.zipCode}`)
                                                            });
                                                        } else {
                                                            toast.error("Prosze uzupełnij NIP.");
                                                        }
                                                    }}><FontAwesomeIcon icon={faSearch} color="white"/></Button>}
                                                </NIPWrapper>
                                                {address && (
                                                    <AddressWrapper>
                                                        <span>{address}</span>
                                                    </AddressWrapper>
                                                )}
                                            </>
                                        )}/>

                                    <Field
                                        name="protocolDate"
                                        type="date"
                                        parse={value => (value === "" ? null : value)}
                                        render={({input, meta}) => (
                                            <InputWrapper>
                                                <Label htmlFor={input.name}>Protokół - data</Label>
                                                <Input {...input} id={input.name} placeholder=""/>
                                            </InputWrapper>
                                        )}/>

                                    <File
                                        name="newRegistrationNumberScan_path"
                                        label="Nowy dowód rejestracyjny"
                                        onFileSelect={(file) => setNewRegistrationNumberScanFile(file)}
                                        initialValue={values.newRegistrationNumberScan}
                                    />

                                    <File
                                        name="invoiceScan_path"
                                        label="Faktura"
                                        onFileSelect={(file) => setInvoiceScanFile(file)}
                                        initialValue={values.invoiceScan}
                                    />

                                    {(!isPartner || (isPartner && isLeader)) && (
                                        <File
                                            name="leasingScan_path"
                                            label="Umowa leasingu"
                                            onFileSelect={(file) => setLeasingScanFile(file)}
                                            initialValue={values.leasingScan}
                                        />
                                    )}

                                    <File
                                        name="protocol_path"
                                        label="Protokół"
                                        onFileSelect={(file) => setProtocolFile(file)}
                                        initialValue={values.protocol}
                                    />
                                </fieldset>
                                <Files name={`photos`} index={0} setFilesToForm={setPhotosToForm} label={`Zdjęcia`}
                                       disabled={disabled}/>
                                <Files name={`files`} index={0} setFilesToForm={setFilesToForm} disabled={disabled}/>
                                {!disabled && <Button>Zapisz</Button>}
                            </div>
                            {withClient && (
                                <div>
                                    <fieldset disabled={disabled} style={{display: "contents"}}>
                                        <Field
                                            name={`financingProvider`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Dostawca finansowania</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Dostawca finansowania</option>
                                                            <option value="Nex1">Nex1</option>
                                                            <option value="Obce">Obce</option>
                                                        </Select>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`institution`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Instytucja*</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Instytucja</option>
                                                            <option value="ALIOR LEASING SP. Z O.O.">ALIOR
                                                                LEASING SP. Z O.O.
                                                            </option>
                                                            <option
                                                                value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON
                                                                CAR LEASE POLSA SP. Z O.O.
                                                            </option>
                                                            <option value="SANTANDER LEASING S.A.">SANTANDER
                                                                LEASING S.A.
                                                            </option>
                                                            <option value="MLEASING SP. Z O.O.">MLEASING SP. Z
                                                                O.O.
                                                            </option>
                                                            <option
                                                                value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI
                                                                FUNDUSZ LEASINGOWY S.A. / EFL S.A.
                                                            </option>
                                                            <option
                                                                value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN
                                                                LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE
                                                            </option>
                                                            <option value="RCI LEASING POLSKA SP. Z O.O.">RCI
                                                                LEASING POLSKA SP. Z O.O.
                                                            </option>
                                                            <option value="PKO LEASING S.A.">PKO LEASING S.A.
                                                            </option>
                                                            <option value="FCA LEASING POLSKA SP. Z O.O.">FCA
                                                                LEASING POLSKA SP. Z O.O.
                                                            </option>
                                                            <option value="IDEA FINANCE SP. Z O.O.">IDEA FINANCE
                                                                SP. Z O.O.
                                                            </option>
                                                            <option value="IDEA FLEET S.A.">IDEA FLEET S.A.
                                                            </option>
                                                            <option value="GETIN LEASING S.A.">GETIN LEASING
                                                                S.A.
                                                            </option>
                                                            <option value="GETIN FLEET S.A.">GETIN FLEET S.A.
                                                            </option>
                                                            <option value="ALIOR LEASING">Alior Leasing</option>
                                                            <option value="ATHLON CAR LEASE POLSKA">Athlon Car
                                                                Lease Polska
                                                            </option>
                                                            <option value="BOŚ LEASING ">BOŚ Leasing</option>
                                                            <option
                                                                value="BMW FINANCIAL SERVICES POLSKA SP. Z O.O.">BMW
                                                                Financial Services Polska Sp. z o.o.
                                                            </option>
                                                            <option
                                                                value="BNP PARIBAS LEASE GROUP SP. Z O.O.">BNP
                                                                Paribas Lease Group Sp. z o.o.
                                                            </option>
                                                            <option
                                                                value="BPS LEASING SPÓŁKA AKCYJNA (BPS LEASING S.A.)">BPS
                                                                Leasing Spółka Akcyjna (BPS Leasing S.A.)
                                                            </option>
                                                            <option
                                                                value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI
                                                                FUNDUSZ LEASINGOWY S.A.
                                                            </option>
                                                            <option value="IDEA GETIN LEASING S.A.">Idea Getin
                                                                Leasing S.A.
                                                            </option>
                                                            <option
                                                                value="IMPULS-LEASING POLSKA SP. Z O.O.">IMPULS-LEASING
                                                                Polska Sp. z o.o.
                                                            </option>
                                                            <option value="ING LEASE (POLSKA) SP. Z O.O.">ING
                                                                LEASE (Polska) Sp. z o.o.
                                                            </option>
                                                            <option value="ING Finance">ING Finance</option>
                                                            <option
                                                                value="MILLENNIUM LEASING SP. Z O.O.">Millennium
                                                                Leasing Sp. z o.o.
                                                            </option>
                                                            <option value="MLEASING SP. Z O.O.">mLeasing Sp. z
                                                                o.o.
                                                            </option>
                                                            <option value="PEKAO LEASING SP. Z O.O.">PEKAO
                                                                LEASING Sp. z o.o.
                                                            </option>
                                                            <option value="PKO LEASING S.A.">PKO LEASING S.A.
                                                            </option>
                                                            <option value="PSA FINANCE POLSKA SP. Z O.O.">PSA
                                                                Finance Polska sp. z o.o.
                                                            </option>
                                                            <option
                                                                value="SANTANDER CONSUMER MULTIRENT SP.Z O.O.">Santander
                                                                Consumer Multirent Sp.z o.o.
                                                            </option>
                                                            <option value="SANTANDER LEASING S.A.">Santander
                                                                Leasing S.A.
                                                            </option>
                                                            <option value="SGB LEASING SP. Z O.O.">SGB Leasing
                                                                Sp. z o.o.
                                                            </option>
                                                            <option value="SIEMENS FINANCE SP. Z O.O.">SIEMENS
                                                                FINANCE Sp. z o.o.
                                                            </option>
                                                            <option
                                                                value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA
                                                                LEASING POLSKA SP. Z O.O.
                                                            </option>
                                                            <option value="ING Finance">VEHIS</option>
                                                        </Select>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name="contractNumber"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Numer umowy</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name={`financingType`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Rodzaj finansowania*</Label>
                                                        <Select {...input} id={input.name}>
                                                            <option value="">Rodzaj finansowania</option>
                                                            <option value="Leasing operacyjny">Leasing
                                                                operacyjny
                                                            </option>
                                                            <option value="Pożyczka">Pożyczka</option>
                                                            <option value="CFM">CFM</option>
                                                            <option value="Leasing finansowy">Leasing
                                                                finansowy
                                                            </option>
                                                            <option value="Kredyt">Kredyt</option>
                                                            <option value="Kredyt">STOK</option>
                                                            <option value="brak">brak</option>
                                                        </Select>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name="financeRate"
                                            type="number"
                                            min="0"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Kurs EUR</Label>
                                                        <Input {...input} id={input.name} placeholder=""
                                                               onChange={(e) => {
                                                                   input.onChange(e);
                                                                   const rate = parseFloat(e.target.value);

                                                                   form.mutators.setValue("secondNet_eur",
                                                                       rate > 0 ? (parseFloat(values.secondNet) / rate).toFixed(2) : null);

                                                                   form.mutators.setValue("payment_eur",
                                                                       rate > 0 ? (parseFloat(values.payment) / rate).toFixed(2) : null);

                                                                   form.mutators.setValue("repurchase_eur",
                                                                       rate > 0 ? (parseFloat(values.repurchase) / rate).toFixed(2) : null);

                                                                   form.mutators.setValue("netInstallment_eur",
                                                                       rate > 0 ? (parseFloat(values.netInstallment) / rate).toFixed(2) : null);
                                                               }}
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`secondNet`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Cena netto*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               onChange={(e) => {
                                                                   input.onChange(e);

                                                                   const eurValue = parseFloat(values.financeRate) > 0 ?
                                                                       parseFloat(e.target.value) / parseFloat(values.financeRate) : 0;

                                                                   form.mutators.setValue("secondNet_eur", eurValue.toFixed(2));

                                                               }}
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name="secondNet_eur"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Cena netto EUR</Label>
                                                        <Input {...input} id={input.name} placeholder="" disabled/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name={`paymentPercentage`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wpłata%*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name={`payment`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wpłata netto PLN*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               onChange={(e) => {
                                                                   input.onChange(e);

                                                                   const eurValue = parseFloat(values.financeRate) > 0 ?
                                                                       parseFloat(e.target.value) / parseFloat(values.financeRate) : 0;

                                                                   form.mutators.setValue("payment_eur", eurValue.toFixed(2));

                                                               }}
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name="payment_eur"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wpłata netto EUR</Label>
                                                        <Input {...input} id={input.name} placeholder="" disabled/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`period`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Okres mc*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               step="1"
                                                               onChange={(e) => {
                                                                   input.onChange(e);
                                                                   const startDate = values.purchased_at;
                                                                   if (startDate) {
                                                                       const d = moment(startDate).add(e.target.value, 'months').toDate();
                                                                       const datestring = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
                                                                       form.mutators.setValue('end_contract_at', datestring)
                                                                   }
                                                               }}
                                                        />
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`repurchasePercentage`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wykup%*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`repurchase`}
                                            type="number"
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wykup netto*</Label>
                                                        <Input {...input} id={input.name} placeholder="" min="0"
                                                               onChange={(e) => {
                                                                   input.onChange(e);

                                                                   const eurValue = parseFloat(values.financeRate) > 0 ?
                                                                       parseFloat(e.target.value) / parseFloat(values.financeRate) : 0;

                                                                   form.mutators.setValue("repurchase_eur", eurValue.toFixed(2));

                                                               }}
                                                               step="0.01"/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name="repurchase_eur"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Wykup netto EUR</Label>
                                                        <Input {...input} id={input.name} placeholder="" disabled/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        {(!isPartner || (isPartner && isLeader)) && (
                                            <Field
                                                name={`provision`}
                                                type="number"
                                                render={({input, meta}) => (
                                                    <>
                                                        <InputSmallWrapper>
                                                            <Label htmlFor={input.name}>Prowizja*</Label>
                                                            <Input {...input} id={input.name} placeholder=""/>
                                                        </InputSmallWrapper>
                                                    </>
                                                )}/>
                                        )}
                                        <Field
                                            name="purchased_at"
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Data zakupy</Label>
                                                    <Input {...input} id={input.name} placeholder=""

                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const period = values.period;
                                                               if (period) {
                                                                   const d = moment(e.target.value).add(period, 'months').toDate();
                                                                   const datestring = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
                                                                   form.mutators.setValue('end_contract_at', datestring)
                                                               }
                                                           }}
                                                    />
                                                </InputSmallWrapper>
                                            )}/>
                                        <Field
                                            name="end_contract_at"
                                            type="date"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <InputSmallWrapper>
                                                    <Label htmlFor={input.name}>Koniec umowy</Label>
                                                    <Input {...input} id={input.name} placeholder=""/>
                                                </InputSmallWrapper>
                                            )}/>
                                        <p>ODPOWIEDZI</p>

                                        <Field
                                            name={`margin`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Marża</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`netInstallment`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Rata netto*</Label>
                                                        <Input {...input} id={input.name} placeholder=""
                                                               onChange={(e) => {
                                                                   input.onChange(e);

                                                                   const eurValue = parseFloat(values.financeRate) > 0 ?
                                                                       parseFloat(e.target.value) / parseFloat(values.financeRate) : 0;

                                                                   form.mutators.setValue("netInstallment_eur", eurValue.toFixed(2));

                                                               }}/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name="netInstallment_eur"
                                            type="number"
                                            parse={value => (value === "" ? null : value)}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Rata netto EUR</Label>
                                                        <Input {...input} id={input.name} placeholder="" disabled/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`additionalProduct`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Produkt dodatkowy</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`additionalFees`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Opłaty dodatkowe*</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`feesSumPercentage`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Suma opłat %</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>
                                        <Field
                                            name={`feesSum`}
                                            render={({input, meta}) => (
                                                <>
                                                    <InputSmallWrapper>
                                                        <Label htmlFor={input.name}>Suma opłat PLN</Label>
                                                        <Input {...input} id={input.name} placeholder=""/>
                                                    </InputSmallWrapper>
                                                </>
                                            )}/>

                                        <Field
                                            name='note'
                                            render={({input}) => (
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Notatka</Label>
                                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                                </InputWrapper>
                                            )}/>
                                    </fieldset>
                                </div>
                            )}
                        </StyledForm>
                    )}
                />
                {!!vehicleId && <Form
                    initialValues={initialValues}
                    onSubmit={values => {
                        const {sold_at} = values;

                        if (sold_at) {
                            setSoldModal(values);
                        } else {
                            toast.warning('Uzupełnij datę sprzedaży');
                        }
                    }}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <Field
                                name="sold_at"
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <Input {...input} id={input.name} placeholder="" disabled={disabled}/>
                                    </>
                                )}/>
                            {!disabled && <Button>Sprzedany</Button>}
                        </form>
                    )}
                />
                }

                <div>
                    {client && (
                        <ClientInfoWrapper>
                            <ClientContactInfo clientSimple={client} isPartner={isPartner}/>
                        </ClientInfoWrapper>
                    )}
                    {(toDo && (!isPartner || (isPartner && isLeader))) && (
                        <ClientInfoWrapper>
                            <h4>Zadanie:</h4>
                            <ToDoInfo toDo={toDo}/>
                        </ClientInfoWrapper>
                    )}

                    {initialValues['slackLink'] && (
                        <ClientInfoWrapper>
                            <a href={initialValues['slackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth/>
                                Kanał Slack
                            </a>
                        </ClientInfoWrapper>
                    )}
                </div>

                {soldModal && <Modal closeModal={() => {
                    setSoldModal(false)
                }}>
                    <P>Czy na pewno chcesz sprzedać pojazd?</P>

                    <ModalButtonWrapper>
                        <Button type="button" onClick={() => {
                            onSold(soldModal);
                            setSoldModal(false);
                        }}>Tak</Button>
                        <Button type="button" inactive onClick={() => setSoldModal(false)}>Nie</Button>
                    </ModalButtonWrapper>
                </Modal>}
            </Grid>
            <Comment vehicleId={vehicleId} policyId={null} toDoId={null} toDoTab={null} archived={false} history={true} />
        </>
    );
};

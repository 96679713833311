import {InputSearch, Table} from "./Table";
import {
    ListHeader, ListHeaderWithoutSort, StyledFontAwesomeIconWhite,
    StyledFontAwesomeSortIcon,
    StyledPanelContentWrapper
} from "./ListComponents";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    faChevronDown,
    faChevronUp,
    faSort,
    faSortDown,
    faSortUp, faTimes
} from "@fortawesome/free-solid-svg-icons";
import {TablePagination} from "@material-ui/core";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Label, Select} from "./Form";
import {Button} from "./Buttons";

const FilterButton = styled.button`
    background: none;
    border: none;
    font-weight: 600;
`;

export const FilterIcon = styled(FontAwesomeIcon)`
    color: ${({theme: {colors: {red}}}) => red};
    font-size: 1.5rem;
    margin-left: 3px;
`;

const FilterPanel = styled.div`
    width: 100%;
`;

const FilterElementsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    width: 100%;
`;

const FilterButtonsContainer = styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const FilterWrapper = styled.div`
    margin-bottom: 20px;
`;

const FilterLabel = styled(Label)`
    margin-right: 5px;
`;

const FilterElement = styled.div`
    margin-right: 20px;
`;

export const List = ({
                         tableColumns,
                         actions,
                         data,
                         cells,
                         initialTotal,
                         readonly,
                         parentCallback,
                         disableMargin,
                         search = false,
                         defaultSort = '',
                         defaultSortDirection = ''
                     }) => {
    const token = useSelector((store) => store.token);
    const [total, setTotal] = useState(null);
    const [sort, setSort] = useState(defaultSort);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [filters, setFilters] = useState({});
    const [initialFilters, setInitialFilters] = useState({});
    const [filtersToggle, setFiltersToggle] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");
    let timeout = 0;

    const searchRecords = (e) => {
        let searchText = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }
    const sendData = () => {
        parentCallback(page, rowsPerPage, sort, sortDirection, searchParam, filters);
    }

    useEffect(() => {
        let initialFiltersTemp = {};
        tableColumns.map((element) => {
            if (element.type === 'choiceFilter') {
                if (element.hasOwnProperty('relationProperty')) {
                    initialFiltersTemp = {
                        ...initialFiltersTemp,
                        [element.property + '_' + element.relationProperty]: ''
                    };
                } else {
                    initialFiltersTemp = {
                        ...initialFiltersTemp,
                        [element.property]: ''
                    };
                }
            }
        });

        setFilters(initialFiltersTemp);
        setInitialFilters(initialFiltersTemp);
    }, []);

    useEffect(() => {
        sendData();
    }, [sort, sortDirection, searchParam, page, rowsPerPage, total, filters]);

    const enableFilters = () => {
        // return search === true || tableColumns.filter(element => element.type === 'choiceFilter').length > 0;
        return tableColumns.filter(element => element.type === 'choiceFilter').length > 0;
    }

    const printChoiceFilter = (element) => {
        return (
            <>
                <FilterElement>
                    <FilterLabel htmlFor={element.property}>{element.name}</FilterLabel>
                    <Select id={element.property}
                            value={element.hasOwnProperty('relationProperty') ? filters[element.property + '.' + element.relationProperty] : filters[element.property]}
                            onChange={e => {
                                var key = element.hasOwnProperty('relationProperty') ? element.property + '_' + element.relationProperty : element.property;

                                setFilters({
                                    ...filters,
                                    [key]: e.target.value
                                });
                            }}>
                        <option value="">Wybierz...</option>
                        {element.filterValues.map((optionValue) => <option
                            value={optionValue.value}>{optionValue.label}</option>)}
                    </Select>
                </FilterElement>
            </>
        );
    }

    const changeSort = (property) => {
        if (property === sort) {
            if (sortDirection === 'desc') {
                setSortDirection('asc');
            } else {
                setSort('');
                setSortDirection('');
            }
        } else {
            setSort(property);
            setSortDirection('desc');
        }
    };

    const printHeader = (value, property, type = '') => {
        if (type === 'sort') {
            return (
                <>
                    <ListHeader onClick={() => {
                        changeSort(property);
                    }}>{value}
                        {sort === property && sortDirection === 'asc' && (
                            <StyledFontAwesomeSortIcon icon={faSortUp}/>)}
                        {sort === property && sortDirection === 'desc' && (
                            <StyledFontAwesomeSortIcon icon={faSortDown}/>)}
                        {(sort !== property || (sort === property && sortDirection === '')) && (
                            <StyledFontAwesomeSortIcon icon={faSort}/>)}
                    </ListHeader>
                </>);
        }

        return (<>
            <ListHeaderWithoutSort>{value}</ListHeaderWithoutSort>
        </>);
    };

    return (
        <>
            {search && (
                <>
                    <div>
                        <InputSearch onChange={(e) => {
                            searchRecords(e);
                        }}/>
                    </div>
                </>
            )}


            <StyledPanelContentWrapper style={
                {
                    'margin': disableMargin ? '40px 0px 0px 0px' : '40px 20px 20px',
                    'padding': disableMargin ? '0px' : '20px 30px',
                    'border-radius': disableMargin ? '0px' : '20px'
                }
            }>
                {enableFilters() === true && (
                    <>
                        <FilterWrapper>
                            <FilterButton onClick={() => {
                                setFiltersToggle(!filtersToggle);
                            }}>Filtry<FilterIcon icon={filtersToggle ? faChevronUp : faChevronDown}/></FilterButton>
                            <FilterPanel style={{display: filtersToggle ? "block" : "none"}}>
                                <FilterElementsContainer>
                                    {tableColumns.map((element) => {
                                        if (element.type === 'choiceFilter') {
                                            return printChoiceFilter(element);
                                        }
                                    })}
                                </FilterElementsContainer>
                                <FilterButtonsContainer>
                                    <Button small smallText onClick={() => {
                                        setFilters(initialFilters);
                                    }}><StyledFontAwesomeIconWhite style={{fontSize: '1rem', marginRight: '5px'}}
                                                                   icon={faTimes}/> Wyczyść filtry</Button>
                                </FilterButtonsContainer>
                            </FilterPanel>
                        </FilterWrapper>
                    </>
                )}
                <Table>
                    <thead>
                    <tr>
                        {tableColumns.map(({name, property, type}, key) => (
                            printHeader(name, property, type)
                        ))}
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((element) => (
                        <tr>
                            {cells(element)}
                            <td style={{display: "flex"}}>
                                {actions(element)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination
                    labelRowsPerPage={'Pokaż na stronie'}
                    component="div"
                    count={total ?? initialTotal}
                    page={page ?? 0}
                    onPageChange={(event, newPage) => {
                        setPage(newPage);
                    }}
                    rowsPerPage={rowsPerPage ?? 25}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(event.target.value);
                    }}
                />
            </StyledPanelContentWrapper>
        </>
    );
}